// src/app/utils/number-to-words.util.ts
export function convertCurrencyToWords(currency: string) {
  const currencyMap: { [key: string]: string } = {
    INR: 'Indian Rupees',
    AUD: 'Australian Dollars',
    USD: 'US Dollars',
  };
  if (currencyMap.hasOwnProperty(currency)) {
    return currencyMap[currency];
  }
  return currency;
}

export function convertNumberToWords(num: number, currency: string): string {
  const ones = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ];
  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];
  const teens = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];

  const unitsINR = ['', 'Thousand', 'Lakh', 'Crore'];
  const unitsOther = ['', 'Thousand', 'Million', 'Billion'];

  if (num === 0) {
    return 'zero';
  }

  let words = '';
  const numString = num.toString();
  const parts = numString.split('.');
  let dollars = parseInt(parts[0]);
  let cents = parts[1] ? parseInt(parts[1]) : 0;

  if (dollars > 0) {
    const units = currency === 'INR' ? unitsINR : unitsOther;

    if (currency === 'INR') {
      // Special handling for INR
      let crore = Math.floor(dollars / 10000000);
      dollars %= 10000000;
      let lakh = Math.floor(dollars / 100000);
      dollars %= 100000;
      let thousand = Math.floor(dollars / 1000);
      let remainder = dollars % 1000;

      if (crore > 0) {
        words +=
          convertNumberToWordsUnderThousand(crore, ones, tens, teens) +
          ' Crore ';
      }
      if (lakh > 0) {
        words +=
          convertNumberToWordsUnderThousand(lakh, ones, tens, teens) + ' Lakh ';
      }
      if (thousand > 0) {
        words +=
          convertNumberToWordsUnderThousand(thousand, ones, tens, teens) +
          ' Thousand ';
      }
      if (remainder > 0) {
        words += convertNumberToWordsUnderThousand(
          remainder,
          ones,
          tens,
          teens
        );
      }
    } else {
      // General handling for other currencies
      let unitIndex = 0;
      while (dollars > 0) {
        if (dollars % 1000 > 0) {
          if (words !== '') {
            words = ' ' + words;
          }
          words =
            convertNumberToWordsUnderThousand(
              dollars % 1000,
              ones,
              tens,
              teens
            ) +
            ' ' +
            units[unitIndex] +
            ' ' +
            words;
        }
        dollars = Math.floor(dollars / 1000);
        unitIndex++;
      }
    }
    words = words.trim();
  } else {
    words = 'zero';
  }

  if (currency === 'INR') {
    words += ' rupees';
    if (cents > 0) {
      words +=
        ' and ' +
        convertNumberToWordsUnderThousand(cents, ones, tens, teens) +
        ' paise';
    }
  } else {
    words += ' dollars';
    if (cents > 0) {
      words +=
        ' and ' +
        convertNumberToWordsUnderThousand(cents, ones, tens, teens) +
        ' cents';
    }
  }

  return words.trim();
}

export function convertNumberToWordsUnderThousand(
  num: number,
  ones: string[],
  tens: string[],
  teens: string[]
): string {
  let words = '';
  if (num >= 100) {
    words += ones[Math.floor(num / 100)] + ' Hundred ';
    num %= 100;
  }
  if (num >= 20) {
    words += tens[Math.floor(num / 10)] + ' ';
    num %= 10;
  } else if (num >= 10) {
    words += teens[num - 10] + ' ';
    num = 0;
  }
  if (num > 0) {
    words += ones[num] + ' ';
  }
  return words.trim();
}
